import { graphql } from 'gatsby';
import React from 'react';
import HeadTags from '../components/global/headTags';
import ContentBackground from '../components/ui/contentBackground';
import translations from '../utils/translations';
import jump from 'jump.js';
import HeroMailChimpForm from '../components/basic/heroMailChimpForm';
import HomeContent from '../components/basic/homeContent';
import ArrowDownButton from '../components/global/arrowDownButton';
import Intro from '../components/global/intro';
import { getLanguage, setPageLanguageUsingSettings } from '../utils/language';
import TemplateWrapperData from '../components/template-wrapper-data';

const exploreClick = () => {
  jump('#content');
};

class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = {
      startPage: false,
      isExiting: false,
      lang: 'en'
    };
    setPageLanguageUsingSettings();
    getLanguage().then(lang => {
      translations.setLanguage(lang);
      this.setState({ lang: lang });
    });
    
  }

  componentWillReceiveProps(nextProps) {
    // issue where first page click doesn't transition properly, so manually doing
    // transition here.
    if (nextProps.transition.status === 'exiting') {
      this.setState({ isExiting: true });
    }
  }

  componentDidMount() {
    this.setState({
      isExiting: false,
    });
    if (!this.props.showIntro) {
      setTimeout(this.startPage, 100);
    }
  }

  shouldReveal() {
    return (
      (this.props.showIntro && this.state.startPage) ||
      (!this.props.showIntro && this.state.startPage)
    );
  }
  
  startPage = () => {
    this.setState({ startPage: true });
  };

  render() {
    const data = this.props.data.allItemsJson.edges.map(({ node }) => ({
      title: node.title,
      path: node.path,
      content_type: node.content_type,
      articles: node.articles,
      paragraphs: node.paragraphs,
      meta_title: node.meta_title,
      meta_image: node.meta_image,
      meta_description: node.meta_description,
      featured_image: node.featured_image,
      intro_heading: node.intro_heading,
      intro_description: node.intro_description,
      video: node.video,
    }));

    return (
      <TemplateWrapperData location={this.props.location}>
        <div
          style={
            this.state.isExiting
              ? {
                opacity: 0,
                transition: `opacity 500ms ease-in-out`,
              }
              : {
                opacity: 1,
                transition: `opacity 500ms ease-in-out`,
              }
          }
        >
          <HeadTags
            title={data[0].meta_title ? data[0].meta_title : data[0].title}
            description={data[0].meta_description}
            image={data[0].meta_image.sizes.hero_md}
            imageTwitter={data[0].meta_image.sizes.hero_sm}
            location={this.props.location}
          />

          <div className={`hero homepage`}>
            {/* TODO: Cleanup this markup and merge it with the Hero component */}
            {this.props.showIntro && (
              <Intro
                onIntroComplete={this.props.onIntroComplete}
                startPage={this.startPage}
              />
            )}

            <ContentBackground
              src={
                data[0].featured_image
                  ? data[0].featured_image.image.sizes.hero_lg
                  : ''
              }
              videoSrc={data[0].video ? data[0].video.url : ''}
              opacity={0.7}
              videoOpacity={1}
              videoSlowFade={0}
              poster={data[0].featured_image.image.sizes.hero_md}
            />

            <div className={`hero-inner`}>
              <div className={`container-inner`}>
                <div
                  className={`hero-heading ${this.shouldReveal() ? 'animated-stagger' : ''
                    }`}
                >
                  <div className={`typeFonts1`}>
                    <h2
                      className={`hero-title`}
                      dangerouslySetInnerHTML={{ __html: data[0].intro_heading }}
                    />
                  </div>
                  <div className={`hero-desc typeFonts2`}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[0].intro_description,
                      }}
                    />
                  </div>
                  <div
                    className="home__challengesWrapper animated-stagger">
                    <HeroMailChimpForm showLogo={false} />
                    {/*
                  <div className="home__challenges typeFonts1 typeColorDark1">
                    <h3>{translations.t('Challenges')}</h3>
                    <p>
                      <a href="/nuclear-weapons">{translations.t('Nuclear Weapons')}</a>
                      <a href="/climate-change">{translations.t('Climate Change')}</a>
                    </p>
                  </div>
                  <div className="home__challenges typeFonts1 typeColorDark1">
                    <h3>{translations.t('Magazine')}</h3>
                      <p>
                        <a href="/post">{translations.t('Outrider Post')}</a>
                      </p>
                  </div>
                  */}
                  </div>
                </div>
                <ArrowDownButton
                  text={translations.t('explore')}
                  handleClick={exploreClick}
                  shouldReveal={this.shouldReveal()}
                />
              </div>
            </div>
          </div>
          <HomeContent articles={data[0].articles} paragraphs={data[0].paragraphs} />
          <style>{`.mc-modal,.mc-modal[style],.mc-banner,.mc-banner[style],.mc-modal-bg,.mc-modal-bg[style]{display:none!important;visibility:hidden!important;overflow:hidden!important;height:0px!important;width:0px!important;opacity:0!important;}`}</style>
        </div>
      </TemplateWrapperData>
    );
  }
}

export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPageQuery {
    allItemsJson(filter: { content_type: { eq: "homepage" }, jsonId: {regex:"/^en/"} }) {
      edges {
        node {
          id
          path
          title
          content_type
          meta_description
          meta_title
          meta_image {
            sizes {
              hero_md
              hero_sm
            }
          }
          featured_image {
            image {
              sizes {
                hero_lg
                hero_md
                hero_sm
                original
              }
            }
          }
          video {
            url
          }
          articles {
            id
              title
                authors {
                  id
              name
            }
            created_date
            short_description
            content_type
            category
            path
            image {
              sizes {
                square_sm
                square_md
                near_square_lg
                near_square_md
                near_square_sm
                original
                standard_md
              }
              alt_text
              title
            }
            related_challenge {
              id
              title
              path
            }
          }
          intro_heading
          intro_description
          ...queryParagraphFields
        }
      }
    }
  }
`;
